@import '../../styles/variables.scss';

.wrapper {
	background: linear-gradient(to top, rgba(255, 255, 255, 0.60), rgba(255, 255, 255, 0.60)), url('../../images/betony.jpg');
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

.container {
	max-width: 1200px;
	margin: 0 auto;
	padding: 100px 20px 100px;

	@media (max-width: $tablet-width) {
		padding: 70px 10px 70px;
	}
}

.title {
	text-align: center;
	max-width: 600px;
	margin: 0 auto;
	font-size: 30px;
	line-height: 35px;
}

.form {
	margin: 0 auto;
	max-width: 800px;
	padding-top: 50px;

	@media (max-width: $mobile-width) {
		max-width: 300px;
	}
}

.labels {
	display: flex;
	justify-content: space-between;

	@media (max-width: $mobile-width) {
		flex-direction: column;
	}
}

.label {
	width: 100%;
	margin-right: 30px;

	&:last-child {
		margin-right: 0;

		@media (max-width: $mobile-width) {
			margin: 0 auto;
		}
	}

	@media (max-width: $mobile-width) {
		margin: 0 auto;
	}
}

.input {
	width: 100%;
	padding: 20px 30px;
	border: 2px solid $grey;
	border-radius: 30px;
	outline: none;
	transition: all 0.7s ease;
	margin-bottom: 20px;
	font-size: 16px;
	font-family: $font-regular;
	color: $black;

	@media (max-width: $mobile-width) {
		padding: 15px 20px;
	}

	&::placeholder {
		color: $black;
	}

	&:hover {
		border: 2px solid $grey-lg-max;
	}

	&:active {
		border: 2px solid $grey-lg-max;
	}

	&:focus {
		border: 2px solid $grey-lg-max;
	}
}

.captcha {
	position: relative;
	padding-top: 30px;

	@media (max-width: $mobile-width) {
		display: block;
		width: 100%;
		transform: scale(0.9);
		transform-origin: 0 0;
	}

	@media (max-width: 412px) {
		display: block;
		width: 100%;
		transform: scale(0.7);
	}
}

.captcha__error {
	position: absolute;
	top: -10px;
	font-size: 13px;
	font-family: $font-medium;
	font-weight: 500;
	color: #ba0000;
}

.button {
	margin-top: 50px;
	margin-bottom: 50px;
	display: flex;
	justify-content: center;
	width: 50%;
	margin: 0 auto;
	color: $white;
	margin-top: 50px;
	border: 2px solid $grey;
	border-radius: 30px;
	padding: 20px 30px;
	text-decoration: none;
	font-size: 16px;
	font-family: $font-max-medium;
	font-weight: 600;
	transition: all 0.5s ease;
	outline: none;
	background-color: $grey;

	@media (max-width: $mobile-width) {
		padding-left: 30px;
		padding-right: 30px;
		width: 100%;
	}

	&:hover {
		color: $grey;
		background-color: $white;
	}
}

.success {
	height: 354px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	@media (max-width: $mobile-width) {
		height: 382px;
	}
}

.success__text {
	font-size: 30px;
	font-family: $font-medium;
	font-weight: 500;
	color: $grey;
	text-align: center;
	margin-bottom: 50px;

	@media (max-width: $mobile-width) {
		font-size: 24px;
		line-height: 30px;
	}
}

.check {
	width: 100px;
	height: 100px;
	background-image: url('../../images/check.svg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center
}