*,
*::before,
*::after {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}

body {
  min-width: 320px;
  min-height: 100%;
  margin: 0;
  padding: 0;
  font-family: $font-regular;
  font-size: 16px;
  line-height: 24px;
  color: $text;
  overflow-x: hidden;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}