@import '../../styles/variables.scss';

.wrapper {
	background-color: $grey;
}

.container {
	max-width: 1200px;
	margin: 0 auto;
	padding: 70px 20px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	justify-content: space-between;
	background: url('../../images/bz.png');
	background-position: contain;
	background-repeat: repeat;
	background-position: left center;

	@media (max-width: $tablet-width) {
		grid-template-columns: 1fr;
	}

	@media (max-width: 400px) {
		padding: 50px 10px;
	}
}

.description {
	color: $white;
}

.title {
	font-family: $font-bold;
	font-size: 34px;
	text-align: center;

	@media (max-width: $mobile-width) {
		font-size: 26px;
		line-height: 30px;
	}
}

.text {
	font-size: 20px;
	text-align: center;

	@media (max-width: $mobile-width) {
		font-size: 16px;
	}
}

.price {
	font-family: $font-medium;
	font-weight: 500;
	font-size: 30px;
	text-align: center;
}

.image {
	max-width: 500px;
	width: 80%;
	height: auto;
	justify-self: end;
	padding-right: 50px;
	box-sizing: content-box;

	@media (max-width: $tablet-width) {
		padding-right: 0;
		justify-self: center;
	}
}