@import '../../styles/variables.scss';

.wrapper {
	background-color: $grey;
	padding: 0 20px;
}

.container {
	max-width: 1200px;
	margin: 0 auto;
	padding: 20px;
}

.text {
	text-align: center;
	color: $text-lg;
	font-family: $font-medium;
	font-weight: 500;
	font-size: 14px;
}