@import '../../styles/variables.scss';

.wrapper {
	display: grid;
	grid-template-rows: 1fr min-content;
	margin: 0 auto;
	min-height: 100vh;
	grid-template-columns: 100%;
	position: relative;
}

.main {
	padding-top: 112px;

	@media (max-width: $desktop-width) {
		padding-top: 100px;
	}

	@media (max-width: $tablet-width) {
		padding-top: 96px;
	}

	@media (max-width: 400px) {
		padding-top: 76px;
	}
}