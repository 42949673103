@import '../../styles/variables.scss';

.wrapper {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.70), rgba(0, 0, 0, 0.70)), url('../../images/zavod-zarya.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 30%;
  background-color: $grey;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 100px 20px;
  color: white;

  @media (max-width: 400px) {
    padding: 50px 10px;
  }
}

.description {
  margin-right: 200px;
  font-family: $font-bold;
  font-weight: 700;
  font-size: 50px;

  @media (max-width: $tablet-width) {
    margin-right: 0;
    font-size: 40px;
    font-family: $font-medium;
    font-weight: 500;
  }
}

.name {
  margin: 0;
  margin-bottom: 50px;
  line-height: 40px;

  @media (max-width: $tablet-width) {
    text-align: center;
  }
}

.title {
  font-size: 45px;
  line-height: 50px;

  @media (max-width: $tablet-width) {
    text-align: center;
    font-size: 40px;
    font-family: $font-medium;
    font-weight: 500;
  }

  @media (max-width: $mobile-width) {
    font-size: 30px;
    line-height: 40px;
  }
}

.list {
  margin: 0;
  margin-top: 100px;
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: $tablet-width) {
    grid-template-columns: 1fr;
    margin-left: auto;
    margin-right: auto;
    max-width: 400px;
    width: 100%;
  }

  @media (max-width: 400px) {
    margin-top: 50px;
  }
}

.item {
  position: relative;
  padding-top: 50px;
  padding-right: 20px;
  padding-bottom: 50px;
  padding-left: 150px;
  font-size: 24px;
  background-color: $grey;
  margin-bottom: 30px;
  color: $text-lg;
  border-radius: 50px;
  align-self: center;

  @media (max-width: $tablet-width) {
    font-size: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 120px;
  }

  @media (max-width: $mobile-width) {
    font-size: 18px;
    padding-left: 80px;
  }

  &:nth-child(2n+1) {
    margin-right: 30px;

    @media (max-width: $tablet-width) {
      margin-right: 0;
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 30px;
    width: 100px;
    height: 100px;
    transform: translateY(-50%);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    @media (max-width: $tablet-width) {
      width: 70px;
      height: 70px;
    }

    @media (max-width: $mobile-width) {
      width: 50px;
      height: 50px;
      left: 20px;
    }
  }

  &__zavod {
    &::before {
      background-image: url('../../images/zavod.png');
    }
  }

  &__time {
    &::before {
      background-image: url('../../images/time.png');
    }
  }

  &__gost {
    &::before {
      background-image: url('../../images/gost.png');
    }

  }

  &__car {
    &::before {
      background-image: url('../../images/beton-car.png');
    }
  }
}
