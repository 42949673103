@import '../../styles/variables.scss';

.wrapper {
	max-width: 1200px;
	margin: 0 auto;
	padding: 70px 20px;
	display: flex;
	align-items: flex-start;

	@media (max-width: $tablet-width) {
		flex-direction: column;
		align-items: center;
	}

	@media (max-width: 400px) {
		padding: 50px 10px;
	}
}

.image {
	width: 500px;
	height: auto;
	margin-right: 30px;

	@media (max-width: $tablet-width) {
		max-width: 600px;
		width: 100%;
		margin-right: 0;
		margin-bottom: 30px;
	}
}

.title {
	font-size: 30px;
	line-height: 40px;
	font-family: $font-bold;
	margin-top: 0;

	@media (max-width: $tablet-width) {
		text-align: center;
	}
}