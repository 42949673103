/*font*/
$font-regular: "Montserrat Regular", "Arial", sans-serif;
$font-medium: "Montserrat Medium", "Arial", sans-serif;
$font-max-medium: "Montserrat 600", "Arial", sans-serif;
$font-bold: "Montserrat Bold", "Arial", sans-serif;

/*color*/
$white: #ffffff;
$black: #000000;
$text: #444444;
$grey: #686c5e;
$grey-lg: #808573;
$grey-lg-max: #969c8a;
$text-lg: #f5f4ed;

/*viewports*/
$desktop-width: 1200px;
$tablet-width: 992px;
$mobile-width: 768px;