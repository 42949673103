@import '../../styles/variables.scss';

.wrapper {
	background-image: url('../../images/yandex-map.jpg');
	background-color: #fff;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 600px;

	@media (max-width: $desktop-width) {
		height: 430px;
	}

	@media (max-width: $mobile-width) {
		height: 500px;
	}
}

.wrapper iframe {

	@media (max-width: $desktop-width) {
		height: 430px;
	}

	@media (max-width: $mobile-width) {
		height: 500px;
	}
}