@import '../../styles/variables.scss';

.wrapper {
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1260px;
  height: 100%;
  margin: 0 auto;
  padding-left: 45px;
  padding-right: 45px;
  margin-top: 50px;
}

.title {
  text-align: center;
  font-size: 30px;
  line-height: 40px;
  font-family: $font-bold;
  margin-top: 70px;
}

.tabs {
  display: flex;
  width: 100%;

  @media (max-width: $mobile-width) {
    width: 100%;
    flex-direction: column;
  }
}

.button {
  width: 25%;
  text-align: center;
  cursor: pointer;
  border: none;
  background-color: transparent;
  font-family: $font-medium;
  font-weight: 500;
  color: $grey;
  font-size: 18px;
  line-height: 21px;
  padding: 25px 25px;
  border: 2px solid $grey;
  border-right: none;

  &:last-child {
    border-right: 2px solid $grey;
  }

  &_active {
    background-color: $grey;
    color: $text-lg;
  }

  @media (max-width: 1199px) {
    font-size: 14px;
    padding: 10px 25px;
  }

  @media (max-width: $mobile-width) {
    width: 100%;
    border: 2px solid $grey;
    border-bottom: none;
    position: relative;

    &::after {
      content: ' ';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 4px 0 4px;
      position: absolute;
      top: 50%;
      right: 20px;
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
      border-color: #ffffff transparent transparent transparent;
    }

    &:last-child {
      border-bottom: 2px solid $grey;
    }
  }
}

.button_list {
  @media (max-width: $mobile-width) {
    width: 100%;
    text-align: center;
    cursor: pointer;
    border: none;
    background-color: transparent;
    font-size: 14px;
    font-family: $font-medium;
    font-weight: 500;
    color: $grey;
    padding: 10px 25px;
    line-height: 21px;
  }
}

.list {
  @media (max-width: $mobile-width) {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}

.item {
  border: 2px solid $grey;
  border-bottom: none;

  &:last-child {
    border-bottom: 2px solid $grey;
  }
}

.services {
  display: flex;
  width: 100%;
  border: 2px solid $grey;
  border-top: none;
  margin-bottom: 50px;
}