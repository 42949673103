@import '../../styles/variables.scss';

.wrapper {
	background-color: $grey;
	color: $text-lg;
	position: fixed;
	width: 100%;
	z-index: 100;
}

.container {
	max-width: 1200px;
	margin: 0 auto;
	padding: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media (max-width: 400px) {
		padding: 10px;
	}
}

.text {
	font-family: $font-medium;
	font-weight: 500;

	@media (max-width: $desktop-width) {
		font-size: 14px;
	}

	&__time {
		position: relative;
		padding-left: 30px;

		@media (max-width: 500px) {
			display: none;
		}

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 20px;
			height: 20px;
			background-image: url('../../images/time.svg');
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
		}
	}

	&__address {
		position: relative;
		padding-left: 30px;
		color: $text-lg;
		text-decoration: none;
		transition: all 0.5s ease;
		outline: none;

		@media (max-width: $mobile-width) {
			display: none;
		}

		&::before {
			content: '';
			position: absolute;
			top: 50%;
			left: 0;
			width: 20px;
			height: 20px;
			transform: translateY(-50%);
			background-image: url('../../images/icon.svg');
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
		}

		&:hover {
			opacity: 0.6;
		}

		&:focus {
			opacity: 0.6;
		}

		&:active {
			opacity: 0.6;
		}
	}

	&__mail {
		position: relative;
		padding-left: 30px;
		color: $text-lg;
		text-decoration: none;
		transition: all 0.5s ease;
		outline: none;

		@media (max-width: $tablet-width) {
			display: none;
		}

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 20px;
			height: 20px;
			background-image: url('../../images/mail.svg');
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
		}

		&:hover {
			opacity: 0.6;
		}

		&:focus {
			opacity: 0.6;
		}

		&:active {
			opacity: 0.6;
		}
	}
}

.links {
	display: flex;
	flex-direction: column;
}

.link {
	position: relative;
	padding-left: 30px;
	font-family: $font-medium;
	font-weight: 500;
	font-size: 24px;
	line-height: 36px;
	color: $text-lg;
	text-decoration: none;
	transition: all 0.5s ease;
	outline: none;

	@media (max-width: $desktop-width) {
		font-size: 20px;
		line-height: 30px;
	}

	@media (max-width: $tablet-width) {
		font-size: 18px;
		line-height: 28px;
	}

	&:hover {
		opacity: 0.6;
	}

	&:focus {
		opacity: 0.6;
	}

	&:active {
		opacity: 0.6;
	}

	&__phone {
		&::before {
			content: '';
			position: absolute;
			top: 50%;
			left: 0;
			width: 20px;
			height: 20px;
			transform: translateY(-50%);
			background-image: url('../../images/phone.svg');
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
		}
	}

	&__whatsapp {
		&::before {
			content: '';
			position: absolute;
			top: 50%;
			left: 0;
			width: 25px;
			height: 25px;
			transform: translateY(-50%);
			background-image: url('../../images/whatsapp.svg');
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
		}
	}
}