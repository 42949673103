@import '../../styles/variables.scss';


.wrapper {
  width: 100%;
}

.container {
  padding: 30px;

  @media (max-width: $mobile-width) {
    padding: 20px 0;
  }
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  @media (max-width: $mobile-width) {
    grid-template-columns: 1fr 1fr;
  }
}

.item {
  justify-self: center;
  margin-bottom: 10px;
  font-family: $font-medium;
  font-weight: 500;
  text-align: center;

  @media (max-width: $mobile-width) {
    font-size: 14px;
    padding: 5px 10px;
    align-self: center;
    line-height: 16px;
  }
}