@font-face {
  font-family: 'Montserrat Regular';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Montserrat Regular'),
    url('../fonts/montserrat-regular.woff2') format('woff2'),
    url('../fonts/montserrat-regular.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat Medium';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Montserrat Medium'),
    url('../fonts/montserrat-500.woff2') format('woff2'),
    url('../fonts/montserrat-500.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat 600';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Montserrat 600'),
    url('../fonts/montserrat-600.woff2') format('woff2'),
    url('../fonts/montserrat-600.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat Bold';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Montserrat Bold'),
    url('../fonts/montserrat-700.woff2') format('woff2'),
    url('../fonts/montserrat-700.woff') format('woff');
}