@import '../../styles/variables.scss';

.wrapper {
	max-width: 1200px;
	margin: 0 auto;
	padding: 100px 20px 100px;

	@media (max-width: $tablet-width) {
		padding: 70px 0 70px;
	}
}

.title {
	font-family: $font-bold;
	font-weight: 700;
	font-size: 40px;
	text-align: center;
	margin-bottom: 50px;

	@media (max-width: $tablet-width) {
		font-size: 36px;
	}
}

.table {
	width: 100%;
	border-collapse: collapse;
}

.table__title {
	background-color: $grey;
	color: $text-lg;
	font-size: 30px;
	text-align: left;
	padding: 30px 20px;

	@media (max-width: $tablet-width) {
		font-size: 24px;
	}

	@media (max-width: $mobile-width) {
		font-size: 20px;
	}
}

.table__item {
	border-bottom: 2px solid $grey;
	color: $grey;
	font-size: 26px;
	padding: 20px;

	@media (max-width: $tablet-width) {
		font-size: 22px;
	}

	@media (max-width: $mobile-width) {
		font-size: 18px;
	}

	&:first-child {
		width: 60%;
	}

	&__small {
		font-size: 20px;

		@media (max-width: $tablet-width) {
			font-size: 16px;
		}

		@media (max-width: $mobile-width) {
			font-size: 14px;
		}
	}
}